<template>
  <section>
    <div class="home-sub-title text-center">
      <p>5501 Adams ST Unit C Matteson, IL 60443</p>
    </div>
    <div class="home-welcome text-center">
      <h2>Our Team</h2>
    </div>
  </section>
  <section class="second-section mt-5">
    <div class="container">
      <div class="row">
        <div class="col-8 mx-auto">
          <h4 class="mb-3 text-center">
            IT IS OUR PROMISE TO YOU, THAT THE TREATMENT YOU RECEIVE, IS JUST AS
            IMPORTANT AS THE EQUIPMENT YOU BUY.
          </h4>
          <p class="fw-bold mb-2">Anton Churkin</p>
          <p class="fst-italic mb-2">PRESIDENT</p>
          <p>
            “My Dealership is simple and straight forward. We represent our
            trucks openly and honestly. We provide great service and support. We
            do all of this while maintaining the
            <span class="fw-bold">best prices</span> in the market. I assure you
            the treatment you receive at
            <span class="fw-bold">E & A Truck Sales</span> will be just as
            exceptional as the equipment you buy.”
          </p>
        </div>
      </div>
    </div>
  </section>
  <base-footer></base-footer>
</template>

<script>
export default {};
</script>

<style scoped>
@media screen and (max-width: 576px) {
  h4 {
    font-size: 1.1rem;
  }
  p {
    font-size: 0.9rem;
  }
}
</style>
